import React, { useState } from 'react';
import CategoryPicker from './CategoryPicker';

const UserPreferences = ({ userDetails, handleUserChange }) => {
    const [tempDetails, setTempDetails] = useState(userDetails);

    const handleTempChange = (e) => {
        const { name, value } = e.target;
        setTempDetails({
            ...tempDetails,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        console.log('Submitting changes:', tempDetails);
        handleUserChange(tempDetails);
    };

    return (
        <div className="tab-content preferences-tab">
        <div className='row'>
        <div>
            <label>
                Business Name:
                <input
                    type="text"
                    name="name"
                    value={tempDetails.name}
                    onChange={handleTempChange}
                />
            </label>
            <label>
                Description:
                <textarea
                    name="description"
                    value={tempDetails.description}
                    onChange={handleTempChange}
                />
            </label>
            <label>
                Phone:
                <input
                    type="text"
                    name="phone"
                    value={tempDetails.phone}
                    onChange={handleTempChange}
                />
            </label>
            <label>
                Website:
                <input
                    type="text"
                    name="website"
                    value={tempDetails.website}
                    onChange={handleTempChange}
                />
            </label>
            <label>
                Email:
                <input
                    type="email"
                    name="email"
                    value={tempDetails.email}
                    onChange={handleTempChange}
                />
            </label>
            <label>
                City:
                <input
                    type="text"
                    name="city"
                    value={tempDetails.city}
                    onChange={handleTempChange}
                />
            </label>
            <label>
                State:
                <input
                    type="text"
                    name="state"
                    value={tempDetails.state}
                    onChange={handleTempChange}
                />
            </label>
            <label>
                ZIP:
                <input
                    type="text"
                    name="zip"
                    value={tempDetails.zip}
                    onChange={handleTempChange}
                />
            </label>
            </div>
            <div>
                <CategoryPicker selectedCategories={tempDetails.categories} setSelectedCategories={(categories) => handleTempChange({ target: { name: 'categories', value: categories } })} />
            </div>
            </div>
            <button onClick={handleSubmit}>Confirm Changes</button>
        </div>
    );
};

export default UserPreferences;