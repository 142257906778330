import React from 'react';
import './Applications.css'; // Importing the CSS file for styling

const Applications = ({ applications }) => {
    return (
        <div className="applications-container">
            <h2>Your Applications</h2>
            <ul className="applications-list">
                {applications?.length > 0 ? (
                    applications.map((application) => {
                        console.log(application);
                        return (
                        <li key={application.application_id} className="application-item">
                            <div className="application-info">
                                <h3>{application.eventName}</h3>
                                <p><strong>Status:</strong> {application.status}</p>
                                <p><strong>Submitted on:</strong> {new Date(application.submitted_on).toLocaleDateString()}</p>
                            </div>
                            <button
                                className="view-details-button"
                                onClick={() => window.location.href = `/application/${application.application_id}`}>
                                View Details
                            </button>
                        </li>
                        );
                })
                ) : (
                    <p>No applications submitted yet.</p>
                )}
            </ul>
        </div>
    );
};

export default Applications;
