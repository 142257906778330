import React, { useState } from 'react';
import { ComboBox, PrimaryButton, Stack, TextField, IconButton } from '@fluentui/react';
import { Tag, Avatar } from '@fluentui/react-components';
import './TagPicker.css';

const TagPicker = ({ availableCategories, selectedTags, setSelectedTags }) => {
    const [tags, setTags] = useState(selectedTags);
    const [inputValue, setInputValue] = useState('');
    const [comboBoxValue, setComboBoxValue] = useState('');
    const [isAddingNewCategory, setIsAddingNewCategory] = useState(false);

    const onAddTag = () => {
        if (inputValue && !tags.includes(inputValue)) {
            setTags([...tags, inputValue]);
            setSelectedTags([...tags, inputValue]);
            setInputValue('');
            setIsAddingNewCategory(false);
        }
    };

    const onInputChange = (event, option, index, value) => {
        const newValue = option ? option.text : value;
        setComboBoxValue(newValue);
        if (option && !tags.includes(newValue)) {
            setTags([...tags, newValue]);
            setSelectedTags([...tags, newValue]);
        }
    };

    const onRemoveTag = (tagToRemove) => {
        setTags(tags.filter(tag => tag !== tagToRemove));
        setSelectedTags(tags.filter(tag => tag !== tagToRemove));
    };
    const comboBoxRef = React.createRef();
    const onFocus = (event) => {
        comboBoxRef.current.focus();
    };

    return (
        <div style={{ border: '1px solid #ccc', padding: '10px', backgroundColor: 'white' }}>
            <Stack tokens={{ childrenGap: 10 }}>
                {tags.map(tag => (
                    <Tag key={tag} style={{ backgroundColor: '#f4f4f4', color: '#333', maxWidth: '400px', alignItems:'center', height: 'fit-content' }}>
                        <Avatar name={tag} color='colorful' image={null} />
                        {tag.length > 35 ? `${tag.substring(0, 35)}...` : tag}
                        <IconButton
                            iconProps={{ iconName: 'Cancel' }}
                            title="Remove tag"
                            ariaLabel="Remove tag"
                            onClick={() => onRemoveTag(tag)}
                            style={{ marginLeft: '8px' }}
                        />
                    </Tag>
                ))}
            </Stack>
            <Stack horizontal style={{ margin: '10px', alignItems: 'baseline', padding:'10px'}}>
                <ComboBox
                componentRef={comboBoxRef}
                    placeholder="Pick Categories"
                    options={availableCategories.map(category => ({ key: category, text: category }))}
                    selectedKey={comboBoxValue}
                    onChange={onInputChange}
                    onFocus={onFocus}
                    allowFreeform={true}
                    styles={{ root: { height: 'fit-content', padding: 25} }}
                                    />
                <IconButton
                    iconProps={{ iconName: isAddingNewCategory ? 'Remove' : 'Add' }}
                    title="Add a new category"
                    ariaLabel="Add a new category"
                    onClick={() => setIsAddingNewCategory(!isAddingNewCategory)}
                    style={{ padding: '10px', margin: '30px' }}
                />
            </Stack>
            {isAddingNewCategory && (
                <Stack horizontal tokens={{ childrenGap: 10 }} style={{ margin: '10px', padding:'10px', alignItems: 'baseline'}}>
                    <TextField
                        placeholder="Add a new category"
                        value={inputValue}
                        onChange={(e, newValue) => setInputValue(newValue)}
                    />
                    <PrimaryButton onClick={onAddTag}>Add</PrimaryButton>
                </Stack>
            )}
        </div>
    );
};

export default TagPicker;