// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// Replace this with your Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyCVNKRJnS8cnumwJByAvppdZmku28RroVg",
    authDomain: "fvconnect-8e986.firebaseapp.com",
    projectId: "fvconnect-8e986",
    storageBucket: "fvconnect-8e986.appspot.com",
    messagingSenderId: "336564230455",
    appId: "1:336564230455:web:d3c73266521bacb73862b2",
    measurementId: "G-HKQS5Q1ZCK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
