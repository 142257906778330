// VendorApplication.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function EventData(event_id) {
    // emulate retrieving vendor data from API
    const eventData = {
        id: 'CF01',
        name: 'County Event',
        dates: 'June 15-20, 2022',
        location: 'Austin, TX',
        attendance: 50000,
        description: 'Annual county event with food, games, and entertainment.',
        requirements: ['Food Handler\'s Permit', 'Insurance Certificate', 'Vendor License'],
        slots: [
            { type: 'Food Truck', available: 5 },
            { type: 'Craft Vendor', available: 10 },
            { type: 'Game Booth', available: 3 }
        ]
    };

    return eventData;
}

const VendorApplication = ({ event_id }) => {
    const navigate = useNavigate();
    const event = EventData(event_id);
    const [formData, setFormData] = useState({
        businessName: '',
        contactInfo: '',
        documents: null,
        availableDates: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        // Submit application logic here
        //route back to the event profile page
        navigate('/confirmation', { state: { event, formData } });

    };

    return (
        <div className="vendor-application">
            <header>
                <h1>Apply for {event.name}</h1>
                <p>Location: {event.location}</p>
                <p>Dates: {event.dates}</p>
                <p>Expected Attendance: {event.attendance}</p>
                <p>Description: {event.description}</p>
            </header>
            <form onSubmit={handleSubmit}>
                <label>
                    Business Name:
                    <input
                        type="text"
                        value={formData.businessName}
                        onChange={(e) =>
                            setFormData({ ...formData, businessName: e.target.value })
                        }
                    />
                </label>
                <label>
                    Contact Info:
                    <input
                        type="text"
                        value={formData.contactInfo}
                        onChange={(e) =>
                            setFormData({ ...formData, contactInfo: e.target.value })
                        }
                    />
                </label>
                <label>
                    Upload Documents:
                    <input
                        type="file"
                        onChange={(e) =>
                            setFormData({ ...formData, documents: e.target.files[0] })
                        }
                    />
                </label>
                <label>
                    Available Dates:
                    <input
                        type="date"
                        value={formData.availableDates}
                        onChange={(e) =>
                            setFormData({ ...formData, availableDates: e.target.value })
                        }
                    />
                </label>
                <button type="submit">Submit Application</button>
            </form>
        </div>
    );
};

export default VendorApplication;
