import React, { useEffect, useRef } from 'react';

const FancyList = () => {
    const listRef = useRef([]); // Use a ref to store the list items

    useEffect(() => {
        // Set up the IntersectionObserver
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Add the 'visible' class to trigger the animation
                    entry.target.classList.add('visible');
                }
            });
        }, {
            threshold: 0.1, // Trigger when 10% of the element is visible
        });

        // Observe each list item
        listRef.current.forEach((item) => {
            if (item) observer.observe(item); // Ensure the item is valid
        });

        const listItems = listRef.current;

        // Cleanup observer on unmount
        return () => {
            listItems.forEach((item) => {
                if (item) observer.unobserve(item);
            });
        };
    }, []);

    return (
        <div>
            <h3>Why use FVConnect?</h3>
            <ul className="fancy-list">
                <li ref={(el) => listRef.current[0] = el}>No more flipping through datebooks or browsing outdated websites to find events.</li>
                <li ref={(el) => listRef.current[1] = el}>Discover events happening soon near you and quickly apply, saving hours of your time!</li>
                <li ref={(el) => listRef.current[2] = el}>Streamline the vendor application process with up-to-date, reliable information.</li>
            </ul>
        </div>
    );
};

export default FancyList;
