import React, {useState } from 'react';
import { DefaultButton, Label, PrimaryButton, TextField, DatePicker } from '@fluentui/react';
import './EventForm.css'; // Import CSS for styling
import TagPicker from './TagPicker.js';
import Search from './Search.js';

const EventForm = ({listings}) => {
    const [eventDetails, setEventDetails] = useState(null);
    const [formData, setFormData] = useState({
        event_name: '',
        start_date: '',
        end_date: '',
        website: '',
        phone: '',
        email: '',
        description: '',
        street_address: '',
        categories: [],
        attendence: '',
        market_size: '',
        contact_name: '',
    });
    const [errors, setErrors] = useState({});

    const interactionButton = (listing) => {
        return (
            <PrimaryButton onClick={() => {
                console.log('Edit event:', listing);
                setEventDetails(listing);
                setFormData({
                    event_name: listing.event_name,
                    start_date: new Date(listing.start_date).toISOString().split('T')[0],
                    end_date: new Date(listing.end_date).toISOString().split('T')[0],
                    website: listing.website,
                    phone: listing.phone,
                    email: listing.email,
                    description: listing.description,
                    street_address: listing.street_address,
                    categories: listing.categories.split(','),
                    attendence: listing.attendence,
                    market_size: listing.market_size,
                    contact_name: listing.contact_name,
                });
            }}>Edit</PrimaryButton>
        );
    }

    const tags = ['Arts & Crafts', 'Food & Drink', 'Music', 'Sports', 'Technology', 'Other'];


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validate = () => {
        let isValid = true;
        const newErrors = {};

        if (!formData.event_name) {
            isValid = false;
            newErrors.event_name = 'Event name is required';
        }
        if (!formData.start_date || new Date(formData.start_date).toString() === 'Invalid Date') {
            isValid = false;
            newErrors.start_date = 'Valid start date is required';
        }
        if (!formData.end_date || new Date(formData.end_date).toString() === 'Invalid Date') {
            isValid = false;
            newErrors.end_date = 'Valid end date is required';
        }
        if (!formData.street_address.match(/^\d+\s[A-z\s]+,\s[A-z]+,\s[A-z]+\s\d+$/)) {
            isValid = false;
            newErrors.street_address = 'Street address must be in the format: 1234 Main St, City, State Zip';
        }
        if (!formData.phone.match(/^\d{3}-\d{3}-\d{4}$/)) {
            isValid = false;
            newErrors.phone = 'Phone number must be in the format: 123-456-7890';
        }
        if (!formData.email.match(/^\S+@\S+\.\S+$/)) {
            isValid = false;
            newErrors.email = 'Valid email is required';
        }
        if (formData.website) {
            try {
                const url = new URL(formData.website);
                if (url.protocol !== 'http:' && url.protocol !== 'https:') {
                    isValid = false;
                    newErrors.website = 'Website must start with http:// or https://';
                }
            } catch (err) {
                isValid = false;
                newErrors.website = 'Valid website URL is required';
            }
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validate()) {
            alert('Please fill out all required fields before submitting the form.');
            return;
        }

        // Handle form submission logic here (e.g., API call to save data)
        if (eventDetails) {
            const json_payload = formData;
            json_payload.event_id = eventDetails.event_id;
            const parseAddress = (address) => {
                const addressParts = address.split(',');
                if (addressParts.length < 3) {
                    alert('Please enter a valid street address in the format: 1234 Main St, City, State Zip');
                    return;
                }
                const street = addressParts[0].trim();
                const city = addressParts[1].trim();
                const stateZip = addressParts[2].trim().split(' ');
                const state = stateZip[0];
                const zip = stateZip[1];
                return { 'street': street, 'city': city, 'state': state, 'zip': zip };
            };

            const addressComponents = parseAddress(formData.street_address);
            json_payload.city = addressComponents.city;
            json_payload.state = addressComponents.state;
            json_payload.zip = addressComponents.zip;

            const url_template = 'https://geocode.maps.co/search?q=@&api_key=67113a80a765d472750448ydzdc317c';

            fetch(url_template.replace('@', formData.street_address))
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to geocode address');
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.length > 0) {
                        const location = data[0]
                        json_payload.latitude = location.lat;
                        json_payload.longitude = location.lon;
                    }
                    fetch('https://steffanic-fvconnect-restapi.azurewebsites.net/api/update_event?event_id=' + eventDetails.event_id, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify(json_payload),
                                })
                                    .then((response) => {
                                        if (!response.ok) {
                                            throw new Error('Failed to update event %s'.replace('%s', response.statusText));
                                        }
                                        return response
                                    })
                                    .then((data) => {
                                        alert('Event information updated successfully!');
                                    })
                                    .catch((err) => {
                                        console.error('Error updating event:', err.message);
                                        alert('Failed to update event. Please try again.');
                                    });
                })
                .catch((err) => {
                    console.error('Error geocoding address:', err.message);
                });
        } else {
            
            alert('Event information submitted successfully!');
        }
    };


    return (
        <div className="event-form">
            <Search listings={listings} interactionButton={interactionButton} setVisibleListingsCallback={() => { }} />
            <div className="event-details" key={eventDetails ? eventDetails.event_id : null}>
                <h1>{eventDetails ? 'Edit Event Information' : 'Add New Event'}</h1>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Event Name"
                        name="event_name"
                        value={formData.event_name}
                        onChange={handleChange}
                        errorMessage={errors.event_name}
                        styles={{ fieldGroup: errors.event_name ? { borderColor: 'red' } : {} }}
                    />
                    <div className="dates">
                        <DatePicker
                            label="Start Date"
                            name="start_date"
                            value={formData.start_date ? new Date(formData.start_date) : null}
                            onSelectDate={(date) => handleChange({ target: { name: 'start_date', value: date.toISOString().split('T')[0] } })}
                            errorMessage={errors.start_date}
                            styles={{ fieldGroup: errors.start_date ? { borderColor: 'red' } : {} }}
                        />
                        <p>To</p>
                        <DatePicker
                            label="End Date"
                            name="end_date"
                            value={formData.end_date ? new Date(formData.end_date) : null}
                            onSelectDate={(date) => handleChange({ target: { name: 'end_date', value: date.toISOString().split('T')[0] } })}
                            errorMessage={errors.end_date}
                            styles={{ fieldGroup: errors.end_date ? { borderColor: 'red' } : {} }}
                        />
                    </div>
                    <TextField
                        label="Street Address"
                        name="street_address"
                        value={formData.street_address}
                        onChange={handleChange}
                        errorMessage={errors.street_address}
                        styles={{ fieldGroup: errors.street_address ? { borderColor: 'red' } : {} }}
                    />
                    <TextField
                        label="Contact Name"
                        name="contact_name"
                        value={formData.contact_name}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        errorMessage={errors.phone}
                        styles={{ fieldGroup: errors.phone ? { borderColor: 'red' } : {} }}
                    />
                    <TextField
                        label="Website"
                        name="website"
                        value={formData.website}
                        onChange={handleChange}
                        errorMessage={errors.website}
                        styles={{ fieldGroup: errors.website ? { borderColor: 'red' } : {} }}
                    />
                    <TextField
                        label="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        errorMessage={errors.email}
                        styles={{ fieldGroup: errors.email ? { borderColor: 'red' } : {} }}
                    />
                    <TextField
                        label="Description"
                        name="description"
                        multiline
                        value={formData.description}
                        onChange={handleChange}
                    />
                    <Label>Categories:</Label>
                    <TagPicker
                        availableCategories={tags}
                        selectedTags={formData.categories}
                        setSelectedTags={(categories) => setFormData(prevState => ({ ...prevState, categories }))}
                    />

                    <TextField
                        label="Attendance"
                        name="attendence"
                        type="number"
                        value={formData.attendence}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Market Size"
                        name="market_size"
                        type="number"
                        value={formData.market_size}
                        onChange={handleChange}
                    />
                    <div className='form-buttons'>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                        <DefaultButton type="button" onClick={() => {
                            setEventDetails(null);
                            setFormData({
                                event_name: '',
                                start_date: '',
                                end_date: '',
                                website: '',
                                phone: '',
                                email: '',
                                description: '',
                                street_address: '',
                                categories: [],
                                attendence: '',
                                market_size: '',
                                contact_name: '',
                            });
                            setErrors({});
                        }}>Clear</DefaultButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EventForm;
