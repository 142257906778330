// App.js
import { React, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import LandingPage from './LandingPage';
import UserProfile from './UserProfile';
import EventProfile from './EventProfile';
import SearchPage from './SearchPage';
import VendorApplication from './VendorApplication';
import AdminDashboard from './AdminDashboard';
import VendorApplicationConfirmationPage from './VendorApplicationConfirmationPage';
import ApplicationDetails from './ApplicationDetails';
import EventForm from './EventForm';
import "./styles.css";
import Login from './Login';
import ProtectedRoute from './ProtectedRoute';
import Signup from './SignUp';
import QuestionFlow from './QuestionFlow';

import ReactGA from 'react-ga4';
import { initializeIcons } from '@fluentui/react/lib/Icons';

initializeIcons(/* optional base url */);
// write a function to access the events called searchListings. The events are at the event_list endpoint.

const applications = [
  { vendorName: 'Best Food Vendor', eventName: 'Texas State Event' },
  { vendorName: 'Amazing Games', eventName: 'County Event' }
];

const stats = {
  events: 15,
  eventUsers: 100,
  vendors: 50,
  vendorUsers: 75,
  pendingApplications: 12,
  approvedApplications: 8
};

ReactGA.initialize('G-HKQS5Q1ZCK');

function App() {
  const  { user, logout }  = useAuth();
  const location = useLocation();
  const [lastTrackedPage, setLastTrackedPage] = useState('');
  const [listings, setListings] = useState([]);
  const [filters, setFilters] = useState({
    dateRangeFrom: null,
    dateRangeTo: null,
    minAttendance: null,
    minMarketSize: null,
    maxDistance: null
  });
  const [questionFlowComplete, setQuestionFlowComplete] = useState(false);

  const navigation = useNavigate();

  useEffect(() => {
    const currentPage = location.pathname;

    // Only send the pageview if the pathname has changed
    if (lastTrackedPage !== currentPage) {
      ReactGA.send({ hitType: 'pageview', page: currentPage });
      setLastTrackedPage(currentPage); // update the last tracked page
    }
  }, [location.pathname, lastTrackedPage]); // Only re-run when location or lastTrackedPage changes

  async function fetchWithRetry(url, retries, delay) {
    for (let i = 0; i < retries; i++) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Failed to fetch listings');
        }
        return await response.json();
      } catch (err) {
        if (i < retries - 1) {
          console.log(`Retrying... (${i + 1}/${retries})`);
          await new Promise(res => setTimeout(res, delay));
        } else {
          throw err;
        }
      }
    }
  }


  async function getEventList() {
    try {
      const data = await fetchWithRetry('https://steffanic-fvconnect-restapi.azurewebsites.net/api/get_event_list', 3, 100);
      setListings(data.events);
    } catch (err) {
      console.log('Error fetching listings:', err.message);
      setListings([]);
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      await getEventList();
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onComplete = (answers) => {
    // set the filters to the answers
    setFilters(answers);
    setQuestionFlowComplete(true);
    }

  useEffect(() => {
    //navigate to the search page when the filters state is updated
    if (filters.dateRangeFrom !== null && questionFlowComplete) {
      setQuestionFlowComplete(false);
      navigation('/search', { state: { filters } });  
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [filters, questionFlowComplete]);

  return (
        <div className="App">
          <header className="header">
            <Link to="/"><h1 className="title">FVConnect</h1></Link>
            <nav className="nav">
              <Link className="nav-link" to="/">Home</Link>
              <Link className="nav-link" to="/search">Browse Events</Link>
              {!user && (
                <>
                  <Link className="nav-link" to="/signup">Sign Up</Link>
                  <Link className="nav-link" to="/login">Login</Link>
                </>
              )}

              {user && (
                <>
                  <Link className="nav-link" to="/user-profile">Profile</Link>
                  <button className="nav-link" onClick={logout}>Logout</button>
                </>
              )}
            </nav>
          </header>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/user-profile/" element={<UserProfile />} />
            <Route path="/event-profile/:event_id" element={<EventProfile />} />
            <Route path="/search" element={<SearchPage listings={listings}/>} />
            <Route path="/question-flow" element={<QuestionFlow onComplete={onComplete} />} />
            <Route path="/vendor-application" element={<VendorApplication />} />
            <Route path="/admin-dashboard" element={<ProtectedRoute><AdminDashboard stats={stats} applications={applications} listings={listings}/></ProtectedRoute>} />
              <Route path="/admin-event-form" element={<ProtectedRoute><EventForm /></ProtectedRoute>} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
          <Route path="/confirmation" element={<VendorApplicationConfirmationPage />} />
          <Route path="/application/:application_id" element={<ApplicationDetails />} />
          </Routes>
          <footer>
            <p>&copy; 2024 FVConnect | <Link to="/terms">Terms</Link> | <Link to="/privacy">Privacy</Link> | <Link to="/admin-dashboard">Admin</Link></p>
          </footer>
        </div>
  );
}

export default function WrappedApp() {
  return (
    <AuthProvider>
      <Router>
      <App />
      </Router>
    </AuthProvider>
  );
}