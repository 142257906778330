// EventDetail.js
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './EventDetail.css'; // Import the CSS file
import L from 'leaflet';

// Fix for default marker icon issue in Leaflet with Webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

// Custom component to update the map center and zoom
const UpdateMapCenter = ({ position, zoom }) => {
    const map = useMap();
    useEffect(() => {
        map.setView(position, zoom);
    }, [position, zoom, map]);
    return null;
};

const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
}

// InfoCard component to display listing information
const InfoCard = ({ listing }) => (
    <div className="info-card">
        <h3>{listing.event_name}</h3>
        <p><strong>Date:</strong> {formatDate(listing.start_date)} - {formatDate(listing.end_date)}</p>
        <p><strong>Address:</strong> {listing.street_address}</p>
        <p><strong>Contact:</strong> {listing.contact_name}</p>
        <p><strong>Email:</strong> {listing.email}</p>
        <p><strong>Phone:</strong> {listing.phone}</p>
        <p><strong>Description:</strong> {listing.description}</p>
    </div>
);

const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
}

// Function to calculate the distance between two points
const getDistance = (userLocation, eventLocation) => {
    const lat1 = userLocation.latitude;
    const lon1 = userLocation.longitude;
    const lat2 = eventLocation.latitude;
    const lon2 = eventLocation.longitude;
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
}

const EventDetail = ({ listings, listingIndex, setSelectedEvent }) => {
    const markerRefs = useRef([]);
    const [userLocation, setUserLocation] = useState({ latitude: null, longitude: null, error: null });

    // get the userlocation from Browser

    // Function to get the location
    const getLocation = useCallback(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setUserLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    error: null
                });
            }
                , (error) => {
                    console.log(error.message);
                    setUserLocation({
                        latitude: null,
                        longitude: null,
                        error: error.message
                    });
                }
            );
        }
    }, []); // Empty array ensures that this function is only created once

    useEffect(() => {
        getLocation(); // Call the function when the component mounts
        // send the slicedListings back to the parent component
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty array ensures that this effect only runs once
    // Open the popup for the selected event
    useEffect(() => {
        if (listingIndex !== -1 && markerRefs.current[listingIndex]) {
            markerRefs.current[listingIndex].openPopup();
        }
    }, [listingIndex]);

    if (!listings || listings.length === 0) {
        //show a blank map if there are no listings
        return <div className="event-detail"><MapContainer center={[0, 0]} zoom={2} className="leaflet-container">
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
        </MapContainer></div>;
    }

    // Create a new list of Objects with valid listings and the index
    const validListings = listings.filter(listing => listing.latitude && listing.longitude);

    if (validListings.length === 0) {
        return <div>No valid events available</div>;
    }
    
    const findIndex = (listings, selectedEvent) => {
        if (!selectedEvent) {
            return -1;
        }
        return listings.findIndex((listing) => listing.event_name === selectedEvent.event_name);
    }

    let position, zoom;
    if (findIndex(validListings, listings[listingIndex]) === -1 || listingIndex === -1) {
        // Show all listings
        const bounds = validListings.map(listing => [listing.latitude, listing.longitude]);
        position = bounds[0]; // Default position to the first listing
        zoom = 5; // Zoom out to show all listings
    } else {
        // Center on the specific listing
        const listing = listings[listingIndex];
        position = [listing.latitude, listing.longitude];
        zoom = 5; // Define the zoom level
    }

    // Function to determine marker color based on the month
    const getMarkerColor = (date) => {
        const month = new Date(date).getMonth();
        const gradientColors = [
            'blue', 'blue', 'blue', 'green', 'green', 'yellow', 'yellow', 'red', 'gold', 'orange', 'orange', 'grey'
        ];
        return gradientColors[month];
    };

    // Custom marker icon
    const createCustomIcon = (color) => {
        return new L.Icon({
            iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-' + color + '.png',
            iconSize: [21, 34],
            iconAnchor: [10, 34],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
        });
    };


    return (
        <div className="event-detail">
            <h2>{listingIndex === -1 ? 'All Events' : listings[listingIndex].event_name}</h2>
            <div className="legend">
                <ul>
                    {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month, index) => (
                        <li key={index} style={{ color: getMarkerColor(new Date(2023, index, 1)) }}>{month}</li>
                    ))}
                </ul>
            </div>
            <MapContainer center={position} zoom={zoom} className="leaflet-container">
                <UpdateMapCenter position={position} zoom={zoom} />
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {validListings.map((listing, index) => (
                    <Marker
                        key={index}
                        position={[listing.latitude, listing.longitude]}
                        icon={createCustomIcon(getMarkerColor(listing.start_date))}
                        eventHandlers={{
                            click: () => {
                                setSelectedEvent(listing);
                            },
                        }}
                    >
                        <Popup autoClose={true}>
                            <div>
                                <strong>{listing.event_name}</strong>
                                <p>{formatDate(listing.start_date)} - {formatDate(listing.end_date)}</p>
                            </div>
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
            {listingIndex !== -1 &&
            <div className='info-card-container'>
            <InfoCard listing={listingIndex === -1 ? listings[0] : listings[listingIndex]} />
                <h3>Recommended Events</h3>
            <div className="recommended-events">
                <ul>
                    {listings
                        .filter((event, idx) => {
                            console.log(getDistance(userLocation, { latitude: event.latitude, longitude: event.longitude }));
                            return idx !== listingIndex && (
                                new Date(event.start_date) > new Date(listings[listingIndex].start_date) ||
                                getDistance(userLocation, { latitude: event.latitude, longitude: event.longitude }) < 10000
                            );
                        })
                        .sort((a, b) => {
                            // sort chronologically by start date beginning with listingIndex, then by distance
                            const dateA = new Date(a.start_date);
                            const dateB = new Date(b.start_date);
                            const distanceA = getDistance(userLocation, { latitude: a.latitude, longitude: a.longitude });
                            const distanceB = getDistance(userLocation, { latitude: b.latitude, longitude: b.longitude });
                            const currentDate = new Date(listings[listingIndex].start_date);

                            if (dateA >= currentDate && dateB >= currentDate) {
                                // Both events are upcoming, sort by date
                                return dateA - dateB;
                            } else if (dateA < currentDate && dateB < currentDate) {
                                // Both events are past, sort by distance
                                return distanceA - distanceB;
                            } else if (dateA >= currentDate && dateB < currentDate) {
                                // dateA is upcoming, dateB is past
                                return -1;
                            } else {
                                // dateA is past, dateB is upcoming
                                return 1;
                            }
                        })
                        .map((event, idx) => (
                            <li className='recommended-event' key={idx} onClick={() => setSelectedEvent(event)}>
                                <strong>{event.event_name}</strong>
                                <p>{formatDate(event.start_date)} - {formatDate(event.end_date)}</p>
                            </li>
                        ))}
                </ul>
            </div>
            </div>
            }
        </div>
    );
};

export default EventDetail;
