import {TextField} from '@fluentui/react';
import React, { useState, useEffect, useCallback } from 'react';
import './Search.css';
import { useLocation } from 'react-router-dom';

const Search = ({listings, interactionButton, setVisibleListingsCallback, resultIsButton}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [visibleListings, setVisibleListings] = useState(15);

    const [listingsState, setListingsState] = useState(listings);
    const [maxDistance, setMaxDistance] = useState(null);
    const [minAttendance, setMinAttendance] = useState(null);
    const [minMarketSize, setMinMarketSize] = useState(null);
    const [dateRangeFrom, setDateRangeFrom] = useState(null);
    const [dateRangeTo, setDateRangeTo] = useState(null);
    const [sortBy, setSortBy] = useState('name');
    const [userLocation, setUserLocation] = useState({
        latitude: null,
        longitude: null,
        error: null
    });

    const {state} = useLocation();
    const filters = state ? state.filters : null;

    useEffect(() => {
        setListingsState(listings);
    }, [listings]);

    useEffect(() => {
        // Set the filters based on the props, with default values
        if(filters) {
            setMaxDistance(filters.maxDistance || null);
            setMinAttendance(filters.minAttendance || null);
            setMinMarketSize(filters.minMarketSize || null);
            setDateRangeFrom(filters.dateRangeFrom || null);
            setDateRangeTo(filters.dateRangeTo || null);
        }
    }, [filters]);

    useEffect(() => {
        setVisibleListingsCallback(filteredAndSortedListings.slice(0, visibleListings));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listingsState, visibleListings, maxDistance, minAttendance, minMarketSize, sortBy, userLocation, searchTerm]);

    const filteredListings = Array.isArray(listingsState) ? listingsState.filter((listing) => {
        return (
            listing.event_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            listing.street_address.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }) : [];

    // Function to get the location
    const getLocation = useCallback(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setUserLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    error: null
                });
            }
                , (error) => {
                    console.log(error.message);
                    setUserLocation({
                        latitude: null,
                        longitude: null,
                        error: error.message
                    });
                }
            );
        }
    }, []); // Empty array ensures that this function is only created once

    useEffect(() => {
        getLocation(); // Call the function when the component mounts
        // send the slicedListings back to the parent component
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty array ensures that this effect only runs once

    const filteredAndSortedListings = filteredListings
        .filter(listing => {
            if (!maxDistance || !userLocation.latitude || !userLocation.longitude) {
                return true;
            }

            // Calculate distance between user location and listing location
            const distance = Math.sqrt(
                Math.pow(userLocation.latitude - listing.latitude, 2) +
                Math.pow(userLocation.longitude - listing.longitude, 2)
            );

            // Convert to miles
            const miles = distance * 69;

            return miles <= maxDistance;
        })
        .filter(listing => {
            if (minAttendance && listing.attendance < minAttendance) {
                return false;
            }
            if (minMarketSize && listing.market_size < minMarketSize) {
                return false;
            }
            return true;
        }).filter(listing => {
            if (dateRangeFrom && dateRangeTo) {
                const fromDate = new Date(dateRangeFrom);
                const toDate = new Date(dateRangeTo);
                const startDate = new Date(listing.start_date);
                const endDate = new Date(listing.end_date);

                return (startDate >= fromDate && startDate <= toDate) || (endDate >= fromDate && endDate <= toDate);
            }
            return true;
        })
        .sort((a, b) => {
            if (sortBy === "distance") {
                const distanceA = Math.sqrt(
                    Math.pow(userLocation.latitude - a.latitude, 2) +
                    Math.pow(userLocation.longitude - a.longitude, 2)
                );
                const distanceB = Math.sqrt(
                    Math.pow(userLocation.latitude - b.latitude, 2) +
                    Math.pow(userLocation.longitude - b.longitude, 2)
                );
                return distanceA - distanceB;
            } else if (sortBy === "upcoming") {
                const dateA = new Date(a.start_date);
                const dateB = new Date(b.start_date);
                const now = new Date();

                // Sort by time until start date, with events happening soon appearing first
                // Previous events should be placed at the end of the array
                if (dateA < now && dateB < now) {
                    return dateA - dateB; // Both events are in the past, sort by date
                } else if (dateA < now) {
                    return 1; // Event A is in the past, place it after event B
                } else if (dateB < now) {
                    return -1; // Event B is in the past, place it after event A
                } else {
                    return (dateA - now) - (dateB - now); // Both events are in the future, sort by time until start date
                }

            } else if (sortBy === "recommended") {
                // Sort by distance if user location is available
                if (userLocation.latitude && userLocation.longitude) {
                    const distanceA = Math.sqrt(
                        Math.pow(userLocation.latitude - a.latitude, 2) +
                        Math.pow(userLocation.longitude - a.longitude, 2)
                    );
                    const distanceB = Math.sqrt(
                        Math.pow(userLocation.latitude - b.latitude, 2) +
                        Math.pow(userLocation.longitude - b.longitude, 2)
                    );

                    if (distanceA !== distanceB) {
                        return distanceA - distanceB;
                    }

                    // If distances are the same, sort by start date
                    const dateA = new Date(a.start_date);
                    const dateB = new Date(b.start_date);
                    const now = new Date();

                    return dateA - now - (dateB - now);

                } else {
                    // Sort by start date if user location is not available
                    const dateA = new Date(a.start_date);
                    const dateB = new Date(b.start_date);
                    const now = new Date();
                    return dateA - now - (dateB - now);
                }
            }

            return a.event_name.localeCompare(b.event_name);
        });

    const slicedListings = filteredAndSortedListings.slice(0, visibleListings);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <div className="search">
            <div className="search-bar">
            <div className='main_filters'>
                <TextField
                    placeholder="Start typing to search..."
                    value={searchTerm}
                    onChange={(e, newValue) => setSearchTerm(newValue || '')}
                />
                <label>
                    Date Range:
                    <div className='date_range'>
                    <input type="date" value={dateRangeFrom || ''} onChange={(e) => {
                            setDateRangeFrom(e.target.value);
                        }
                    } />
                    <div>to</div>
                    <input type="date" value={dateRangeTo || ''} onChange={(e) => setDateRangeTo(e.target.value)} />
                    </div>
                </label>
                <label>
                    Sort by:
                    <select onChange={(e) => setSortBy(e.target.value)}>
                        <option value="name">Name</option>
                        <option value="distance">Distance</option>
                        <option value="upcoming">Upcoming</option>
                        <option value="recommended">Recommended</option>
                    </select>
                </label>
                </div>
                <div className='market_size'>
                <label>
                    Max Distance:
                    <input
                        type="number"
                        value={maxDistance || ''}
                        onChange={(e) => {

                            setMaxDistance(e.target.value ? parseInt(e.target.value) : null);
                            }
                        }
                    />
                </label>
                <label>
                    Min Attendance:
                    <input
                        type="number"
                        value={minAttendance || ''}
                        onChange={(e) => setMinAttendance(e.target.value ? parseInt(e.target.value) : null)}
                    />
                </label>
                <label>
                    Min Market Size:
                    <input
                        type="number"
                        value={minMarketSize || ''}
                        onChange={(e) => setMinMarketSize(e.target.value ? parseInt(e.target.value) : null)}
                    />
                </label>
                </div>
            </div>
            <div className="search-results">
                {listingsState.length === 0 ? (
                    <>
                        <p>Loading</p>
                        <img src="./images/carousel.gif" alt="loading" />
                    </>
                ) : slicedListings.length > 0 ? (
                    slicedListings.map((listing, index) => (
                        <div key={index} className="listing">
                            <h3>{listing.event_name}</h3>
                            <p>{formatDate(listing.start_date)} - {formatDate(listing.end_date)}</p>
                            <p>{listing.street_address}</p>
                            {interactionButton(listing)}
                        </div>
                    ))
                ) : (
                    <p>No results, please widen your search</p>
                )}
                {slicedListings.length > 0 && visibleListings < filteredListings.length && (
                    <button className='show-more' onClick={() => {
                        setVisibleListings(visibleListings + 15);
                        setVisibleListingsCallback(filteredAndSortedListings.slice(0, visibleListings + 15));
                    }}>
                        Show More
                    </button>
                )}
            </div>
            <p>Showing {slicedListings.length} of {filteredAndSortedListings.length} events</p>
        </div>
    );
}

export default Search;