import React from 'react';

import './CategoryPicker.css';

// Sample categories
const categoriesList = [
    'Food & Beverage',
    'Clothing & Apparel',
    'Games & Entertainment',
    'Handmade Crafts',
    'Health & Beauty',
    'Art & Design',
    'Tech & Gadgets'
];

const CategoryPicker = ({ selectedCategories, setSelectedCategories }) => {

    const handleCategoryChange = (category) => {
        if (selectedCategories.includes(category)) {
            // If the category is already selected, remove it
            setSelectedCategories(selectedCategories.filter(cat => cat !== category));
        } else {
            // Otherwise, add the category to the selected list
            setSelectedCategories([...selectedCategories, category]);
        }
    };

    return (
        <div className="category-picker">
            <h3>Pick Categories:</h3>
            <ul>
                {categoriesList.map((category, index) => (
                    <li key={index}>
                        <label>
                            <input
                                type="checkbox"
                                checked={selectedCategories.includes(category)}
                                onChange={() => handleCategoryChange(category)}
                            />
                            {category}
                        </label>
                    </li>
                ))}
            </ul>

            <div>
                <h4>Selected Categories:</h4>
                {selectedCategories.length > 0 ? (
                    <ul>
                        {selectedCategories.map((cat, idx) => (
                            <li key={idx}>{cat}</li>
                        ))}
                    </ul>
                ) : (
                    <p>No categories selected.</p>
                )}
            </div>
        </div>
    );
};

export default CategoryPicker;
