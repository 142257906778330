import React, { useState } from 'react';
import { DefaultButton, Label, PrimaryButton, TextField,  } from '@fluentui/react';
import './VendorForm.css'; // Import CSS for styling
import Search from './Search';
import TagPicker from './TagPicker';
import Slider from '@mui/material/Slider';

const VendorForm = ({listings}) => {
    const [vendorDetails, setVendorDetails] = useState(null);
    const [formData, setFormData] = useState({
        vendor_name: '',
        website: '',
        phone: '',
        email: '',
        description: '',
        street_address: '',
        categories: [],
        socialMediaLinks: [],
        serviceZipCodes: [],
        pricing_model: '',
        menuOfServices: [],
        resale_number: '',
        insurance_number: '',
        photoLinks: [],
    });

    const [errors, setErrors] = useState({});
    const [priceLow, setPriceLow] = useState(0);
    const [priceHigh, setPriceHigh] = useState(100);
    
    
        const interactionButton = (listing) => {
            return (
                <button onClick={() => {
                    setVendorDetails(listing);
                    setFormData({
                        vendor_name: listing.vendor_name,
                        start_date: new Date(listing.start_date).toISOString().split('T')[0],
                        end_date: new Date(listing.end_date).toISOString().split('T')[0],
                        website: listing.website,
                        phone: listing.phone,
                        email: listing.email,
                        description: listing.description,
                        street_address: listing.street_address,
                        categories: listing.categories.split(','),
                        attendence: listing.attendence,
                        market_size: listing.market_size,
                        contact_name: listing.contact_name,
                    });
                }}>Edit</button>
            );
        }

    const tags = ['Arts & Crafts', 'Food & Drink', 'Music', 'Sports', 'Technology', 'Other'];


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validate = () => {
        let isValid = true;
        const newErrors = {};

        if (!formData.vendor_name) {
            isValid = false;
            newErrors.vendor_name = 'Vendor name is required';
        }
        if (!formData.street_address.match(/^\d+\s[A-z\s]+,\s[A-z]+,\s[A-z]+\s\d+$/)) {
            isValid = false;
            newErrors.street_address = 'Street address must be in the format: 1234 Main St, City, State Zip';
        }
        if (!formData.phone.match(/^\d{3}-\d{3}-\d{4}$/)) {
            isValid = false;
            newErrors.phone = 'Phone number must be in the format: 123-456-7890';
        }
        if (!formData.email.match(/^\S+@\S+\.\S+$/)) {
            isValid = false;
            newErrors.email = 'Valid email is required';
        }
        if (formData.website) {
            try {
                const url = new URL(formData.website);
                if (url.protocol !== 'http:' && url.protocol !== 'https:') {
                    isValid = false;
                    newErrors.website = 'Website must start with http:// or https://';
                }
            } catch (err) {
                isValid = false;
                newErrors.website = 'Valid website URL is required';
            }
        }
        formData.serviceZipCodes.forEach((zip, index) => {
            if (!zip.match(/^\d{5}$/)) {
            isValid = false;
            newErrors.serviceZipCodes = newErrors.serviceZipCodes || [];
            newErrors.serviceZipCodes[index] = `Zip code ${index + 1} must be 5 digits`;
            }
        });

        formData.socialMediaLinks.forEach((link, index) => {
            if (!link.match(/^(http|https):\/\/[^\s$.?#].[^\s]*$/)) {
            isValid = false;
            newErrors.socialMediaLinks = newErrors.socialMediaLinks || [];
            newErrors.socialMediaLinks[index] = `Social media link ${index + 1} must be a valid URL`;
            }
        });

        formData.photoLinks.forEach((link, index) => {
            if (!link.match(/^(http|https):\/\/[^\s$.?#].[^\s]*$/)) {
            isValid = false;
            newErrors.photoLinks = newErrors.photoLinks || [];
            newErrors.photoLinks[index] = `Photo link ${index + 1} must be a valid URL`;
            }
        });

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validate()) {
            alert('Please correct the errors in the form before submitting');
            return;
        }
        // Handle form submission logic here (e.g., API call to save data)
        alert('Vendor information submitted successfully!');
    };


    return (
        <div className="vendor-form">
            <Search listings={listings} interactionButton={interactionButton} setVisibleListingsCallback={() => {}} />
            <div className="vendor-details" key={vendorDetails ? vendorDetails.vendor_id : null}>
                <h1>{vendorDetails ? 'Edit Vendor Information' : 'Add New Vendor'}</h1>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Vendor Name"
                        name="vendor_name"
                        value={formData.vendor_name}
                        onChange={handleChange}
                        errorMessage={errors.vendor_name}
                        styles={{ fieldGroup: errors.vendor_name ? { borderColor: 'red' } : {} }}
                    />
                    <TextField
                        label="Street Address"
                        name="street_address"
                        value={formData.street_address}
                        onChange={handleChange}
                        errorMessage={errors.street_address}
                        styles={{ fieldGroup: errors.street_address ? { borderColor: 'red' } : {} }}
                    />
                    <TextField
                        label="Phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        errorMessage={errors.phone}
                        styles={{ fieldGroup: errors.phone ? { borderColor: 'red' } : {} }}
                    />
                    <TextField
                        label="Website"
                        name="website"
                        value={formData.website}
                        onChange={handleChange}
                        errorMessage={errors.website}
                        styles={{ fieldGroup: errors.website ? { borderColor: 'red' } : {} }}
                    />
                    <TextField
                        label="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        errorMessage={errors.email}
                        styles={{ fieldGroup: errors.email ? { borderColor: 'red' } : {} }}
                    />
                    <TextField
                        label="Description"
                        name="description"
                        multiline
                        value={formData.description}
                        onChange={handleChange}
                    />
                    <Label>Categories:</Label>
                    <TagPicker
                        availableCategories={tags}
                        selectedTags={formData.categories}
                        setSelectedTags={(categories) => setFormData(prevState => ({ ...prevState, categories }))}
                    />
                    <Label>Service Zip Codes:</Label>
                    {formData.serviceZipCodes && formData.serviceZipCodes.map((zip, index) => (
                        <div key={index} className="service-zip-code">
                            <TextField
                                label={`Zip Code ${index + 1}`}
                                value={zip}
                                onChange={(e) => {
                                    const newZips = [...formData.serviceZipCodes];
                                    newZips[index] = e.target.value;
                                    setFormData(prevState => ({ ...prevState, serviceZipCodes: newZips }));
                                }}
                                errorMessage={errors.serviceZipCodes && errors.serviceZipCodes[index]}
                                styles={{ fieldGroup: errors.serviceZipCodes && errors.serviceZipCodes[index] ? { borderColor: 'red' } : {} }}
                            />
                            <DefaultButton
                                type="button"
                                onClick={() => {
                                    const newZips = formData.serviceZipCodes.filter((_, i) => i !== index);
                                    setFormData(prevState => ({ ...prevState, serviceZipCodes: newZips }));
                                }}
                            >
                                Remove
                            </DefaultButton>
                        </div>
                    ))}
                    <DefaultButton
                        type="button"
                        onClick={() => {
                            setFormData(prevState => ({
                                ...prevState,
                                serviceZipCodes: [...(prevState.serviceZipCodes || []), '']
                            }));
                        }}
                    >
                        Add Zip Code
                    </DefaultButton>
                    <Label>Price Range:</Label>
                    <Slider 
                        value={[priceLow, priceHigh]}
                        onChange={(_, newValue) => {
                            setPriceLow(newValue[0]);
                            setPriceHigh(newValue[1]);
                        }}
                        min={0}
                        max={2000}
                        marks={[
                            { value: 100, label: '$100' },
                            { value: 250, label: '$250' },
                            { value: 500, label: '$500' },
                            { value: 1000, label: '$1000' },
                            { value: 2000, label: '$2000+' },
                        ]}
                    />
                    <div className='price-range-labels'>
                        <Label> ${priceLow}</Label>
                        <p>-</p>
                        <Label> ${priceHigh}{priceHigh===2000 ? "+" : ""}</Label>
                        <select
                            name="pricing_model"
                            value={formData.pricing_model || ''}
                            onChange={(e) => setFormData(prevState => ({ ...prevState, pricing_model: e.target.value }))}
                        >
                            <option value="" disabled>Select Pricing Model</option>
                            <option value="Per Day">Per Day</option>
                            <option value="Per Week">Per Week</option>
                            <option value="Per Event">Per Event</option>
                        </select>
                    </div>
                    <Label>Menu of Services:</Label>
                    {formData.menuOfServices && formData.menuOfServices.map((service, index) => (
                        <div key={index} className="menu-of-services">
                            <TextField
                                label={`Service ${index + 1}`}
                                value={service}
                                onChange={(e) => {
                                    const newServices = [...formData.menuOfServices];
                                    newServices[index] = e.target.value;
                                    setFormData(prevState => ({ ...prevState, menuOfServices: newServices }));
                                }}
                                errorMessage={errors.menuOfServices && errors.menuOfServices[index]}
                                styles={{ fieldGroup: errors.menuOfServices && errors.menuOfServices[index] ? { borderColor: 'red' } : {} }}
                            />
                            <DefaultButton
                                type="button"
                                onClick={() => {
                                    const newServices = formData.menuOfServices.filter((_, i) => i !== index);
                                    setFormData(prevState => ({ ...prevState, menuOfServices: newServices }));
                                }}
                            >
                                Remove
                            </DefaultButton>
                        </div>
                    ))}
                    <DefaultButton
                        type="button"
                        onClick={() => {
                            setFormData(prevState => ({
                                ...prevState,
                                menuOfServices: [...(prevState.menuOfServices || []), '']
                            }));
                        }}
                    >
                        Add Service
                    </DefaultButton>
                    <TextField
                        label="Resale Number"
                        name="resale_number"
                        value={formData.resale_number || ''}
                        onChange={handleChange}
                        errorMessage={errors.resale_number}
                        styles={{ fieldGroup: errors.resale_number ? { borderColor: 'red' } : {} }}
                    />
                    <TextField
                        label="Insurance Number"
                        name="insurance_number"
                        value={formData.insurance_number || ''}
                        onChange={handleChange}
                        errorMessage={errors.insurance_number}
                        styles={{ fieldGroup: errors.insurance_number ? { borderColor: 'red' } : {} }}
                    />
                    <Label>Social Media Links:</Label>
                    {formData.socialMediaLinks && formData.socialMediaLinks.map((link, index) => (
                        <div key={index} className="social-media-link">
                            <TextField
                                label={`Link ${index + 1}`}
                                value={link}
                                onChange={(e) => {
                                    const newLinks = [...formData.socialMediaLinks];
                                    newLinks[index] = e.target.value;
                                    setFormData(prevState => ({ ...prevState, socialMediaLinks: newLinks }));
                                }}
                                errorMessage={errors.socialMediaLinks && errors.socialMediaLinks[index]}
                                styles={{ fieldGroup: errors.socialMediaLinks && errors.socialMediaLinks[index] ? { borderColor: 'red' } : {} }}
                            />
                            <DefaultButton
                                type="button"
                                onClick={() => {
                                    const newLinks = formData.socialMediaLinks.filter((_, i) => i !== index);
                                    setFormData(prevState => ({ ...prevState, socialMediaLinks: newLinks }));
                                }}
                            >
                                Remove
                            </DefaultButton>
                        </div>
                    ))}
                    <DefaultButton
                        type="button"
                        onClick={() => {
                            setFormData(prevState => ({
                                ...prevState,
                                socialMediaLinks: [...(prevState.socialMediaLinks || []), '']
                            }));
                        }}
                    >
                        Add Social Media Link
                    </DefaultButton>
                    <Label>Photo Links:</Label>
                    {formData.photoLinks && formData.photoLinks.map((link, index) => (
                        <div key={index} className="photo-link">
                            <TextField
                                label={`Link ${index + 1}`}
                                value={link}
                                onChange={(e) => {
                                    const newLinks = [...formData.photoLinks];
                                    newLinks[index] = e.target.value;
                                    setFormData(prevState => ({ ...prevState, photoLinks: newLinks }));
                                }}
                                errorMessage={errors.photoLinks && errors.photoLinks[index]}
                                styles={{ fieldGroup: errors.photoLinks && errors.photoLinks[index] ? { borderColor: 'red' } : {} }}
                            />
                            <DefaultButton
                                type="button"
                                onClick={() => {
                                    const newLinks = formData.photoLinks.filter((_, i) => i !== index);
                                    setFormData(prevState => ({ ...prevState, photoLinks: newLinks }));
                                }}
                            >
                                Remove
                            </DefaultButton>
                        </div>
                    ))}
                    <DefaultButton
                        type="button"
                        onClick={() => {
                            setFormData(prevState => ({
                                ...prevState,
                                photoLinks: [...(prevState.photoLinks || []), '']
                            }));
                        }}
                    >
                        Add Photo Link
                    </DefaultButton>
                    <div className='form-buttons'>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                        <DefaultButton type="button" onClick={() => {
                            setVendorDetails(null);
                            setFormData({
                                vendor_name: '',
                                website: '',
                                phone: '',
                                email: '',
                                description: '',
                                street_address: '',
                                categories: [],
                                socialMediaLinks: [],
                                serviceZipCodes: [],
                                pricing_model: '',
                                menuOfServices: [],
                                resale_number: '',
                                insurance_number: '',
                                photoLinks: [],
                            });
                            setErrors({});
                        }}>Clear</DefaultButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default VendorForm;
