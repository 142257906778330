// SearchPage.js
import React, { useState } from 'react';
import Search from './Search';
import EventDetail from './EventDetail';
import './SearchPage.css';

const SearchPage = ({listings}) => {
    const [visibleListings, setVisibleListings] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);


    const handleEventClick = (event) => {
        setSelectedEvent(event);
    };

    const interactionButton = (event) => {
        return <button onClick={() => handleEventClick(event)}>View Details</button>;
    }

    function handleVisibleListingsChange(listings) {
        setVisibleListings(listings);
    }

    const findIndex = (listingsToSearch, selectedEvent) => {
        if (!selectedEvent) {
            return -1;
        }

        const index = listingsToSearch.findIndex((listing) => listing.event_name === selectedEvent.event_name);
        return index;
    }

    return (
        <div className="search-page">
        <Search listings={listings} interactionButton={interactionButton} setVisibleListingsCallback={handleVisibleListingsChange} />
                    <EventDetail listings={visibleListings} listingIndex={selectedEvent===null ? -1 : findIndex(visibleListings, selectedEvent)} setSelectedEvent={handleEventClick} />
        </div>
    );
};

export default SearchPage;
