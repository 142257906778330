import React, { useState, useEffect } from 'react';
import './QuestionFlow.css';

const QuestionFlow = ({ onComplete }) => {
    const [step, setStep] = useState(0);
    const [animationClass, setAnimationClass] = useState('step-enter');
    const [answers, setAnswers] = useState({
        dateRangeFrom: '',
        dateRangeTo: '',
        minAttendance: 1000,
        minMarketSize: 1000,
        maxDistance: 1000
    });

    useEffect(() => {
        setAnimationClass('step-enter');
    }, [step]);

    const handleNext = () => {
        setAnimationClass('step-exit');
        setTimeout(() => {
            setStep(step + 1);
        }, 500); // Match the duration of the animation
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAnswers({
            ...answers,
            [name]: value
        });
    };

    const handleSubmit = () => {
        onComplete(answers);
    };

    return (
        <div className="question-flow">
            {step === 0 && (
                <div className={`step step-0 ${animationClass}`}>
                    <label>When do you want to search for events?</label>
                    <div className="input-group">
                        <label>From:</label>
                        <input
                            type="date"
                            name="dateRangeFrom"
                            value={answers.dateRangeFrom}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-group">
                        <label>To:</label>
                        <input
                            type="date"
                            name="dateRangeTo"
                            value={answers.dateRangeTo}
                            onChange={handleChange}
                        />
                    </div>
                    <button className="next-button" onClick={handleNext}>Next</button>
                </div>
            )}
            {step === 1 && (
                <div className={`step step-1 ${animationClass}`}>
                    <label>What is the minimum attendance you are looking for?</label>
                    <input
                        type="number"
                        name="minAttendance"
                        value={answers.minAttendance}
                        onChange={handleChange}
                    />
                    <button className="next-button" onClick={handleNext}>Next</button>
                </div>
            )}
            {step === 2 && (
                <div className={`step step-2 ${animationClass}`}>
                    <label>What is the minimum market size you are looking for?</label>
                    <input
                        type="number"
                        name="minMarketSize"
                        value={answers.minMarketSize}
                        onChange={handleChange}
                    />
                    <button className="next-button" onClick={handleNext}>Next</button>
                </div>
            )}
            {step === 3 && (
                <div className={`step step-3 ${animationClass}`}>
                    <label>How far away would you like to search?</label>
                    <input
                        type="number"
                        name="maxDistance"
                        value={answers.maxDistance}
                        onChange={handleChange}
                    />
                    <button className="submit-button" onClick={handleSubmit}>Submit</button>
                </div>
            )}
        </div>
    );
};

export default QuestionFlow;