// EventProfile.js
import React from 'react';

export function EventData( event_id ) {
    // emulate retrieving event data from API
    const eventData = {
        id: 'CF01',
        name: 'County Event',
        start_date: 'June 15, 2022',
        end_date: 'June 20, 2022',
        location: 'Austin, TX',
        attendance: 50000,
        description: 'Annual county event with food, games, and entertainment.',
        requirements: ['Food Handler\'s Permit', 'Insurance Certificate', 'Vendor License'],
        slots: [
            { type: 'Food Truck', available: 5 },
            { type: 'Craft Vendor', available: 10 },
            { type: 'Game Booth', available: 3 }
        ]
    };

    return eventData;
}

const EventProfile = ({ event_id }) => {
    const event = EventData(event_id);
    const navigateToApplication = () => {
        window.location.href = `/vendor-application?event_id=${event.id}`;
    };

    return (
        <div className="event-profile">
            <header>
                <h1>{event.name}</h1>
                <p>{event.start_date} - {event.end_date}</p>
                <p>Location: {event.location}</p>
                <p>Expected Attendance: {event.attendance}</p>
            </header>
            <section className="about-event">
                <h2>About the Event</h2>
                <p>{event.description}</p>
            </section>
            <section className="vendor-requirements">
                <h3>Vendor Requirements</h3>
                <ul>
                    {event.requirements.map((requirement, index) => (
                        <li key={index}>{requirement}</li>
                    ))}
                </ul>
            </section>
            <section className="slots">
                <h3>Available Slots</h3>
                <ul>
                    {event.slots.map((slot, index) => (
                        <li key={index}>{slot.type}: {slot.available}</li>
                    ))}
                </ul>
            </section>
            <button onClick={navigateToApplication}>Apply as Vendor</button>
        </div>
    );
};

export default EventProfile;
