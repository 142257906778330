// LandingPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import FancyList from './FancyList';
import './LandingPage.css';

const LandingPage = () => {
    return (
        <div className="landing-page">
            <section className="hero">
                <h1 className="hero-title">Welcome to FVConnect</h1>
                <p className="hero-intro">
                    Find events looking for vendors near you! Our platform provides up-to-date information on events across the US. Start by answering a few questions to find the perfect events for you.
                </p>
            <div>
                <Link to="/question-flow" className="cta-button">Get Started</Link>
            </div>
            </section>
            <section className="benefits">
                <FancyList />
            </section>
            <section className="testimonials">
                <h3>What Our Users Say</h3>
                <p>"This platform made it easy for us to find the perfect events!"</p>
            </section>
        </div>
    );
};

export default LandingPage;
