import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const VendorApplicationConfirmationPage = () => {
    const location = useLocation();
    const { event, formData } = location.state || {}; // Access state passed from the VendorApplication component

    return (
        <div className="confirmation-page">
            <header>
                <h1>Application Submitted Successfully!</h1>
                <p>Thank you for applying to {event?.name}!</p>
            </header>

            <section>
                <h2>Application Summary</h2>
                <p><strong>Event Name:</strong> {event?.name}</p>
                <p><strong>Location:</strong> {event?.location}</p>
                <p><strong>Event Dates:</strong> {event?.dates}</p>
                <p><strong>Business Name:</strong> {formData?.businessName}</p>
                <p><strong>Contact Information:</strong> {formData?.contactInfo}</p>
                <p><strong>Available Dates:</strong> {formData?.availableDates}</p>
            </section>

            <section>
                <p>You will be notified once your application has been reviewed.</p>
                <p>To track the status of your application and communicate with the event, click the button below:</p>
                <Link to="/application/A001">
                    <button>View This Application</button>
                </Link>
            </section>

            <footer>
                <Link to={`/event-profile/${event?.id}`}>
                    <button>Return to {event?.name} Profile</button>
                </Link>
                <Link to="/search">
                    <button>Browse Other Events</button>
                </Link>
            </footer>
        </div>
    );
};

export default VendorApplicationConfirmationPage;
