import React, { useEffect, useState } from 'react';
import './ApplicationDetails.css'; // Import CSS for styling



const ApplicationDetails = () => {
    // Get the application ID from the URL
    const application_id = window.location.pathname.split('/').pop();
    const [message, setMessage] = useState('');
    const [application, setApplication] = useState({});
    const [chatHistory, setChatHistory] = useState([]);
    const [event, setEvent] = useState({});
    // Fetch application details from the server
    useEffect(() => {
        try {
            fetch(`http://steffanic-dev.duckdns.org:3012/application/${application_id}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch application details');
                    }
                    return response.json();
                }).then((data) => {
                    setApplication(data);
                }).catch((err) => {
                    console.log('Error fetching application details:', err.message);
                });
        } catch (err) {
            // Handle error
            console.log('Error fetching application details:', err.message);
        }
    }, [application_id]);

    // Fetch chat history from the server
    useEffect(() => {
        try {
            fetch(`http://steffanic-dev.duckdns.org:3012/chat/${application_id}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch chat history');
                    }
                    return response.json();
                }).then((data) => {
                    setChatHistory(data);
                }).catch((err) => {
                    console.log('Error fetching chat history:', err.message);
                });
        } catch (err) {
            // Handle error
            console.log('Error fetching chat history:', err.message);
        }
    }, [application_id]);

    // Fetch event details from the server based on the event-id in the application
    useEffect(() => {
        if (!application.event_id) {
            return;
        }
        try {
            fetch(`http://steffanic-dev.duckdns.org:3012/event/${application.event_id}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch event details');
                    }
                    return response.json();
                }).then((data) => {
                    setEvent(data);
                }).catch((err) => {
                    console.log('Error fetching event details:', err.message);
                });
        } catch (err) {
            // Handle error
            console.log('Error fetching event details:', err.message);
        }
    }, [application.event_id]);

    // Function to handle form submission and send the POST request
    const handleSubmit = () => {
        console.log('Submitting message:', message);

        const sender_id = application.vendor_id;
        const recipient_id = application.event_id;
        const createdAt = new Date().toISOString();

        // Validate required fields
        if (!message || !createdAt || !sender_id || !recipient_id) {
            console.log('All fields are required');
            return;
        }

        const data = {
            message_id: 'TM'+(chatHistory.length + 1),
            message: message,
            sender_id: sender_id,
            recipient_id: recipient_id,
            created_at: createdAt,
        };
        setChatHistory([...chatHistory, data]);
        setMessage('');

        try {
            fetch(`http://steffanic-dev.duckdns.org:3012/update_chat/${application_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to update chat');
                    }
                    return response.json();
                }).catch((err) => {

                    console.log('Error updating chat:', err.message);});
        } catch (err) {
            // Handle error

                    console.log('Error updating chat:', err.message);}
    };

    return (
        <div className="application-details">
            <h1>Application Details for {event.event_name}</h1>
            <div className="application-info">
                <p><strong>Application ID:</strong> {application.application_id}</p>
                <p><strong>Status:</strong> {application.status}</p>
                <p><strong>Submitted on:</strong> {new Date(application.submitted_on).toLocaleDateString()}</p>
            </div>

            <div className="documents-section">
                <h2>Uploaded Documents</h2>
                {application.documents?.length > 0 ? (
                    <ul>
                        {application.documents.map((doc, index) => (
                            <li key={index}>{doc}</li>
                        ))}
                    </ul>
                ) : (
                    <p>No documents uploaded yet.</p>
                )}
            </div>

            <div className="chat-section">
                <h2>Chat History</h2>
                <div className="chat-history">
                    {chatHistory.length > 0 ? (
                        chatHistory.map((chat) => (
                            <div key={chat.message_id} className={`chat-message ${chat.sender === 'You' ? 'sent' : 'received'}`}>
                                <span className="chat-sender">{chat.sender_id}</span>
                                <p>{chat.message}</p>
                                <span className="chat-timestamp">{chat.created_at}</span>
                            </div>
                        ))
                    ) : (
                        <p>No chat history available.</p>
                    )}
                </div>
                <div>
                <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type your message..."
                />

                </div>
                <div>
                    <button type="button" onClick={handleSubmit}>Send</button>
                </div>
                </div>
            </div>
    );
};

export default ApplicationDetails;
