// AdminDashboard.js
import React, { useState } from 'react';
import EventForm from './EventForm';
import VendorForm from './VendorForm';
import './AdminDashboard.css';

const AdminDashboard = ({ stats, applications, listings }) => {
    const [activeTab, setActiveTab] = useState('events');

    return (
        <div className="admin-dashboard">
            <div className="tabs">
                <p>Admin Dashboard</p>
                <button className={activeTab === 'events' ? 'active' : ''} onClick={() => setActiveTab('events')}>Events</button>
                <button className={activeTab === 'vendors' ? 'active' : ''} onClick={() => setActiveTab('vendors')}>Vendors</button>
            </div>
            <div className="tab-content">
                {activeTab === 'events' && <EventForm listings={listings}/>}
                {activeTab === 'vendors' && <VendorForm listings={listings}/>}
            </div>
        </div>
    );
};

export default AdminDashboard;
