// UserProfile.js
import React, { useState } from 'react';
import './UserProfile.css';
import UserPreferences from './UserPreferences';
import Applications from './Applications';


const mockVendorData = {
    vendor_id: "V001",
    user_id: "U123",
    name: "Tasty Treats",
    categories: ["Food Truck", "Desserts"],
    description: "We offer a variety of delicious desserts, including ice cream, cupcakes, and cookies. Perfect for any event!",
    phone: "(123) 456-7890",
    website: "http://www.tastytreats.com",
    email: "info@tastytreats.com",
    city: "Austin",
    state: "TX",
    zip: "78701"
};



const mockApplications = [
    {
        application_id: 'A001',
        event_id: 'F001',
        eventName: 'Texas State Event',
        status: 'Pending',
        submitted_on: '2023-09-12T12:34:56Z',
        documents: ['Business License.pdf', 'Insurance Certificate.pdf'],
        chatHistory: [
            { id: 1, text: 'Thank you for your application!', timestamp: '2023-09-15 14:30', sender: 'Event Admin' },
            { id: 2, text: 'Is there anything else you need from us?', timestamp: '2023-09-16 10:00', sender: 'Event Admin' }
        ]
    },
    {
        application_id: 'A002',
        event_id: 'F002',
        eventName: 'County Event',
        status: 'Accepted',
        submitted_on: '2023-08-25T15:22:10Z',
        documents: ['Business License.pdf', 'Insurance Certificate.pdf'],
        chatHistory: [
            { id: 1, text: 'Thank you for your application!', timestamp: '2023-09-15 14:30', sender: 'Event Admin' },
            { id: 2, text: 'Is there anything else you need from us?', timestamp: '2023-09-16 10:00', sender: 'Event Admin' }
        ]
    },
    {
        application_id: 'A003',
        event_id: 'F003',
        eventName: 'Spring Fest',
        status: 'Rejected',
        submitted_on: '2023-07-19T11:11:11Z',
        documents: ['Business License.pdf', 'Insurance Certificate.pdf'],
        chatHistory: [
            { id: 1, text: 'Thank you for your application!', timestamp: '2023-09-15 14:30', sender: 'Event Admin' },
            { id: 2, text: 'Is there anything else you need from us?', timestamp: '2023-09-16 10:00', sender: 'Event Admin' }
        ]
    }
];

const userData = mockVendorData;
const applications = mockApplications;

const UserProfile = ({ user_id}) => {
    const [activeTab, setActiveTab] = useState('preferences');
    const [userDetails, setUserDetails] = useState(userData);

    const handleUserChange = (e) => {
        const { name, value } = e;
        setUserDetails({ ...userDetails, [name]: value });
    };


    return (
        <div className="user-profile">
            <header>
                <h1>{userDetails.name}</h1>
                <p>{userDetails.description}</p>
            </header>

            <div className="tabs">
                <button className={activeTab === 'applications' ? "active" : ""} onClick={() => setActiveTab('applications')}>Applications</button>
                <button className={activeTab === 'preferences' ? "active" : ""} onClick={() => setActiveTab('preferences')}>Preferences</button>
            </div>

            {activeTab === 'preferences' && <UserPreferences userDetails={userDetails} handleUserChange={handleUserChange} />}

            {activeTab === 'applications' && (
                <div className="tab-content applications-tab">
                    <Applications applications={applications} />
                </div>
            )}
        </div>
    );
};

export default UserProfile;